import IHeader from "@component/IHeader/index.vue";
import IFooter from "@component/IFooter/index.vue";
import { NUM_REG, PWD_REG, REMARK_REG } from "@/utils/reg";
// import local from "@/utils/local";
import { findPassword, sendCode, checkVerifyCode } from "@lib";
export default {
  data() {
    // 验证手机号

    const checkAcc = (rules, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号"));
        this.remarkTrue = true;
      } else if (!NUM_REG.test(value)) {
        callback(new Error("手机格式错误!"));
        this.remarkTrue = true;
      } else {
        callback();
        this.remarkTrue = false;
        // local.set("telphone", value);
        // this.resetForm.telphone = JSON.parse(local.get("telphone"));
      }
    };
    //   短信验证码
    const checkAccRemark = (rules, value, callback) => {
      if (value === "") {
        callback(new Error("请输入短信验证码"));
      } else if (!REMARK_REG.test(value)) {
        callback(new Error("短信验证码格式错误!"));
      } else {
        callback();
      }
    };
    /*  重设密码验证码
    const checkPwdRemark = (rules, value, callback) => {
      if (value === "") {
        callback(new Error("请输入短信验证码"));
      } else if (!REMARK_REG.test(value)) {
        callback(new Error("短信验证码格式错误!"));
      } else if (value !== this.resetForm.remark) {
        callback(new Error("短信验证码错误!"));
      } else {
        callback();
      }
    }; */

    // 验证密码
    const checkPwd = (rules, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (!PWD_REG.test(value)) {
        callback(new Error("请输入6-12位字符包括字母、数字及下划线"));
      } else {
        callback();
      }
    };
    //   再次确认密码
    const checkPwdAgain = (rules, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.resetForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      current: 1,
      remarkTrue: true,
      // 账号表单信息
      checkAccForm: {
        telphone: "",
        remark: "",
      },
      checkAccRules: {
        telphone: { validator: checkAcc, trigger: "blur" },
        remark: { validator: checkAccRemark, trigger: "blur" },
      },
      // 重设密码表单信息
      resetForm: {
        password: "",
        checkpassword: "",
        // remark: "",
      },
      resetRules: {
        password: { validator: checkPwd, trigger: "blur" },
        checkpassword: { validator: checkPwdAgain, trigger: "blur" },
        // remark: { validator: checkPwdRemark, trigger: "blur" },
      },
      setCode: "获取验证码",
      // yzmType: 2,
    };
  },
  methods: {
    // 点击去登录
    goLogin() {
      this.$router.push("/login");
    },
    // 获取短信验证码
    async getYzm() {
      this.validateBtn();
      let res = await sendCode(
        this.checkAccForm.telphone,
      );
      if (res.result.code === 200) {
        this.$message.success("验证码发送成功");
      } else {
        this.$message.error(res.result.message);
      }
      //后台需传入的参数
      // let data = {
      //   phone: this.form.phone,
      //   type: this.yzmType
      // };
      // this.$ajax.post("/sms/getVerificationCode", data, res => {
      //   if (res.code == 0) {
      //     this.form.yzm = res.data.data;
      //   }
      // });
    },
    validateBtn() {
      //倒计时
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.setCode = "发送验证码";
        } else {
          this.setCode = time + "秒后重试";
          this.disabled = true;
          time--;
        }
      }, 1000);
    },
    // 确认账号验证表单信息
    submitCheckAccForm(checkAccForm) {
      this.$refs[checkAccForm].validate(async (valid) => {
        if (valid) {
          let res = await checkVerifyCode({
            code: this.checkAccForm.remark,
            phone: this.checkAccForm.telphone,
          });
          if (res.result.code === 200) {
            if (this.current < 4) {
              this.current++;
            }
          } else {
            this.$message.error(res.result.message);
          }
        } else {
          return false;
        }
      });
    },
    // 确认重设密码表单信息
    submitResetForm(resetForm) {
      this.$refs[resetForm].validate(async (valid) => {
        if (valid) {
          let res = await findPassword({
            code: this.checkAccForm.remark,
            password: this.resetForm.password,
            phone: this.checkAccForm.telphone,
          });
          if (res.status === "success") {
            this.current = 3;
          } else {
            this.$message.error("密码找回失败");
          }
        } else {
          return false;
        }
      });
    },
  },
  components: {
    [IHeader.name]: IHeader,
    [IFooter.name]: IFooter,
  },
};
